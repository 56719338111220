<template>
  <base-section id="about-due-diligence">

    <base-section-heading
        color="grey lighten-2"
        :title="$t('section.due_diligence_headline')"
    >
      {{ $t('features_due_diligence_text') }}
    </base-section-heading>

    <div class="justify-center">
      <base-image :src="require(`@/assets/evaluate_masked.png`)" contain max-width="33%" class="mx-auto mb-8"/>
    </div>

    <v-container>
      <v-row>
        <v-col
            v-for="card in cards[$i18n.locale]"
            :key="card.title"
            cols="12"
            md="4"
        >
          <base-info-card v-bind="card"/>
        </v-col>
      </v-row>
    </v-container>

  </base-section>

</template>

<script>
import BaseImage from '@/components/base/Img';

export default {
  name: 'SectionAboutDueDiligence',
  components: {BaseImage},
  data: () => ({
    cards: {
      en: [
        {
          title: 'Evaluate Risk',
          subtitle: 'Risk analyses and screening',
          text: 'Evaluate Risk helps you to identify where your highest risks are in your value chain in order for you to focus your resources where they are needed the most. We build tailor made risk analyses to fit each business. Evaluate Risk also allows you to verify companies world-wide and to ensure that they are not on any warning- or sanction lists. Evaluate Risk also provides you with the possibility to screen potential business partners for adverse media. ',
          callout: '01',
        },
        {
          title: 'Evaluate SAQ',
          subtitle: 'Self-assessment questionnaires',
          text: 'Evaluate SAQ (Self-assessment Questionnaires) is an efficient tool for communication with your business partners and evaluating their sustainability performance. It also allows you to collect the data and information you need. We tailor make the SAQ’s to fit your sustainability ambition and your value chain.',
          callout: '02',
        },
        {
          title: 'Evaluate Site Visits',
          subtitle: 'On-site assessments',
          text: 'With our Site Visits app you have the possibility to collect information on your business partners’ sustainability performance when visiting them on site. The app provides an easy to use protocol for the user to fill in on their mobile phone. Protocols are also tailor made to fit your needs.',
          callout: '03',
        },
      ],
      sv: [
        {
          title: 'Evaluate Risk',
          subtitle: 'Riskanalyser och screening',
          text: 'Evaluate Risk hjälper dig att identifiera var de högsta riskerna finns i din värdekedja så att du kan fokusera dina resurser där de behövs som mest. Vi bygger skräddarsydda riskanalyser för att passa varje verksamhet. Evaluate Risk låter dig också verifiera företag över hela världen och säkerställa att de inte finns med på några varnings- eller sanktionslistor. Evaluate Risk ger dig också möjligheten att screena potentiella affärspartners för negativ publicitet (Adverse Media).',
          callout: '01',
        },
        {
          title: 'Evaluate SAQ',
          subtitle: 'Självskattningsformulär',
          text: 'Evaluate SAQ (Self-assessment Questionnaires) är ett effektivt verktyg för kommunikation med dina affärspartners och för att utvärdera deras hållbarhetsprestanda. Det låter dig också samla in den data och information du behöver. Vi skräddarsyr dina formulär för att matcha din ambition och din värdekedja.',
          callout: '02',
        },
        {
          title: 'Evaluate Site Visits',
          subtitle: 'Utvärdering på plats',
          text: 'Med vår app för utvärdering vid platsbesök har du möjlighet att samla in information om dina affärspartners hållbarhetsprestanda när du besöker dem på plats. Appen erbjuder ett lättanvänt protokoll för användaren att fylla i på sin mobiltelefon. Protokollen är skräddarsydda för att passa dina behov.',
          callout: '03',
        },
      ],
    },
  }),
}
</script>
